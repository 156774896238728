/* You can add global styles to this file, and also import other style files */
:root {
  --paradise-pink: #ef476fff;
  --orange-yellow-crayola: #ffd166ff;
  --caribbean-green: #06d6a0ff;
  --blue-ncs: #118ab2ff;
  --prussian-blue: #1f2d40ff;
  --text-grey: #c2c2c2;
  --rosso-scuro: #902f2f;
  --verde: #2ca284;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style-type: none;
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
}

body {
  display: flex;
  flex-direction: column;
}

#nav-toggle {
  display: none;
}

main {
  margin-top: 53px;
  padding: 2rem 1.5rem;
  background: /*#f1f5f9;*/ #fff;
  min-height: calc(100vh - 55px); /* sottrarre 116px per usare footer */
  position: relative;
}

.titolo-sezione {
  color: var(--blue-ncs); font-size: 2rem; font-weight: normal; line-height: 2.7rem; margin: 0;
}


.main-area {
  margin-left: 250px;
  transition: margin-left 300ms;
}

@media only screen and (max-width: 1200px) {
  .main-area {
    margin-left: 70px;
  }

  .main-area header {
    width: calc(100% - 70px);
    left: 70px;
  }
}

@media only screen and (max-width: 960px) {
  .main-area {
    margin-left: 70px;
  }

  .main-area header {
    width: calc(100% - 70px);
    left: 70px;
  }
}

@media only screen and (max-width: 768px) {
  .main-area {
    width: 100%;
    margin-left: 0rem;
  }
}


.cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;
  margin-top: 1rem;
}

.card-single {
  display: flex;
  justify-content: space-between;
  background: #fff;
  padding: 2rem;
  border-radius: 4px;
}

.card-single p {
  font-size: 0.8rem;
}

.card-single div:last-child span {
  font-size: 3rem;
  color: var(--caribbean-green);
}

.card-single div:first-child span {
  color: var(--text-grey);
}

.card-single:first-child {
  background: var(--caribbean-green);
}

.card-single:first-child h1,
.card-single:first-child div:last-child span,
.card-single:first-child div:first-child span {
  color: #fff;
}

.card-single:first-child p {
  color: rgb(3, 109, 3);
}

/*CUSTOM CARDS STYLE PINK */
.card-single:nth-child(2) {
  background: var(--paradise-pink);
}

.card-single:nth-child(2) h1,
.card-single:nth-child(2) div:first-child span,
.card-single:nth-child(2) div:nth-child(2) span {
  color: #fff;
}

.card-single:nth-child(2) p {
  color: #ab0000;
}

/*CUSTOM CARDS STYLE YELLOW */
.card-single:nth-child(3) {
  background: var(--orange-yellow-crayola);
}

.card-single:nth-child(3) h1,
.card-single:nth-child(3) div:last-child span,
.card-single:nth-child(3) div:first-child span,
.card-single:nth-child(3) div:nth-child(3) span {
  color: rgb(116, 77, 0);
}

.card-single:nth-child(3) p {
  color: rgb(146, 95, 1);
}

/*CUSTOM CARDS STYLE BLUE */
.card-single:nth-child(4) {
  background: var(--blue-ncs);
}

.card-single:nth-child(4) h1,
.card-single:nth-child(4) div:last-child span,
.card-single:nth-child(4) div:first-child span,
.card-single:nth-child(4) div:nth-child(4) span {
  color: #fff;
}

.card-single:nth-child(4) p {
  color: rgb(151, 229, 255);
}

.card {
  background: #fff;
  border-radius: 5px;
}

.card-header,
.card-body {
  padding: 1rem;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f0f0f0;
}

.card-header button {
  background: var(--paradise-pink);
  border-radius: 10px;
  color: #fff;
  font-size: 0.8rem;
  padding: 0.5rem 1rem;
  border: 1px solid var(--paradise-pink);
}

.spinnerColor {
  color: var(--blue-ncs);
}

.required-asterisk{
  color: red;
  font-weight: bold;
  margin-left: 3px;
  font-size: 1.2em;
}

.stileRuoli.p-dropdown {
  .p-dropdown-item::first-letter {
      text-transform: capitalize;
  }
  .p-dropdown-item {
      text-transform: lowercase;
  }
  .p-dropdown-label::first-letter {
      text-transform: capitalize;
  }
  .p-dropdown-label {
      text-transform: lowercase;
  }
}

.fix-non-required-inline {
  margin-top: .25rem;
}

.success-text {
  color: var(--green-500);
}

/* Bug fix upload cancel icon */
.p-fileupload-row.ng-star-inserted div:nth-child(4) button span::before {
  content: "\e93d";
}

.p-fileupload-row.ng-star-inserted div:nth-child(4) button {
  background-color: transparent;
  color: #D32F2F;
  border-color: transparent;
}

/* Bug fix p-chart canvas scrollbar */

.canvas-div {
  max-height: 20rem;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: var(--scroll-bar-color) var(--scroll-bar-bg-color);
}

$slim-scrollbar-width: 0.1em;
$slim-scrollbar-fadeTime: 0.3s;

.slim-scrollbar::-webkit-scrollbar {
  width: $slim-scrollbar-width;
  //padding: 20px;
  // margin: 20em;
}

.slim-scrollbar::-webkit-scrollbar-corner {
  background: rgba(0,0,0,0.5);
}

.slim-scrollbar::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}

.slim-scrollbar {
  -webkit-mask-image: linear-gradient(to top, transparent, black), linear-gradient(to left, transparent $slim-scrollbar-width, black $slim-scrollbar-width);
  mask-image: linear-gradient(to top, transparent, black), linear-gradient(to left, transparent $slim-scrollbar-width, black $slim-scrollbar-width);
  -webkit-mask-size: 100% 20000px;
  mask-size: 100% 20000px;
  -webkit-mask-position: left bottom;
  mask-position: left bottom;

  -o-transition: mask-position $slim-scrollbar-fadeTime, -webkit-mask-position $slim-scrollbar-fadeTime;
  -ms-transition: mask-position $slim-scrollbar-fadeTime, -webkit-mask-position $slim-scrollbar-fadeTime;
  -moz-transition: mask-position $slim-scrollbar-fadeTime, -webkit-mask-position $slim-scrollbar-fadeTime;
  -webkit-transition: mask-position $slim-scrollbar-fadeTime, -webkit-mask-position $slim-scrollbar-fadeTime;
  transition: mask-position $slim-scrollbar-fadeTime, -webkit-mask-position $slim-scrollbar-fadeTime;
}

.slim-scrollbar:hover {
  -webkit-mask-position: left top;
  mask-position: left top;
  -o-transition: mask-position $slim-scrollbar-fadeTime, -webkit-mask-position $slim-scrollbar-fadeTime;
  -ms-transition: mask-position $slim-scrollbar-fadeTime, -webkit-mask-position $slim-scrollbar-fadeTime;
  -moz-transition: mask-position $slim-scrollbar-fadeTime, -webkit-mask-position $slim-scrollbar-fadeTime;
  -webkit-transition: mask-position $slim-scrollbar-fadeTime, -webkit-mask-position $slim-scrollbar-fadeTime;
  transition: mask-position $slim-scrollbar-fadeTime, -webkit-mask-position $slim-scrollbar-fadeTime;
}

@media only screen and (max-width: 1200px) {
  #nav-toggle {
    display: flex;
  }
}

@media only screen and (max-width: 960px) {
  .cards {
    grid-template-columns: repeat(2, 1fr);
  }

  #nav-toggle {
    display: flex;
  }


}

@media only screen and (max-width: 768px) {
  .cards {
    grid-template-columns: repeat(2, 1fr);
  }

  #nav-toggle {
    display: flex;
  }



  #nav-toggle:checked ~ .main-area {
    margin-left: 0 !important;
  }
}

@media only screen and (max-width: 560px) {
  .cards {
    grid-template-columns: 100%;
  }
}
